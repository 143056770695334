export default [
  {
    key: "search",
    label: "field.search",
    type: "text",
  },
  {
    key: 'galleryCategoryId',
    label: 'field.galleryCategory',
    type: 'nAsynSingleSelection',
    repository: 'galleryCategory',
    selectionKey: 'id',
    selectionLabel: 'nameEn',
    clearable: true,
  },
  {
    key: "isEnable",
    label: "field.status",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.active", value: "1" },
      { text: "field.inactive", value: "0" },
    ],
    lg: "auto",
  },
]
